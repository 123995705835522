@use "src/styles/0-base/_responsive.scss" as *;

.gold-color-button {
  font-size: 16px;
  height: 48px;
  color: var(--brand-palette-700);
  background-color: var(--gold-palette-400);
  border: none;
  font-weight: 600;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: 0.3s;
  padding: 12px 24px;
  white-space: nowrap;
  p{
    font-weight: 600;
    margin: 0;
  }
  &:hover {
    border-radius: 8px;
    background-color: var(--gold-palette-500);
  }

  &:disabled {
    background-color: var(--slate-palette-200);
    color: var(--slate-palette-400);
  }
}

//BOTÓN SECUNDARIO STROKED

.blue-stroked-button,
.gold-stroked-button {
  padding: 12px 24px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid var(--gold-palette-400);
  border-radius: 8px;
  color: var(--gold-palette-400);
  background: rgba(199, 173, 119, 0.05);
  cursor: pointer;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  flex-direction: row-reverse;
  gap: 8px;
  p {
    margin: 0;
    font-weight: 600;
  }
  &:hover {
    background-color: #c7ad771a;
  }

  &:disabled {
    background: none;
    color: lightgrey;
    border: 2px solid lightgrey;
  }
}

.blue-stroked-button {
  border: 1px solid var(--slate-palette-400);
  background-color: transparent;
  color: var(--brand-palette-500);
  width: fit-content;
  flex-direction: row;
  &:hover {
    background-color: var(--brand-palette-50);
  }
}
//BOTÓN PARA ENVIAR FORMULARIOS

.send-button {
  padding: 12px 24px;
  background-color: var(--gold-palette-400);
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  p {
    font-size: 15px;
    color: var(--brand-palette-700);
    margin: 0;
    
    font-weight: 600;
  }
  &:hover {
    transition: 0.5s;
  }

  &:disabled {
    background-color: var(--slate-palette-200);
    p{
      color: var(--slate-palette-400);
    }
    cursor: auto;
  }
}

//BOTONES VIEJOS

.header_button {
  height: 40px;
  width: 170px;
  font-weight: 500;
  font-size: 15px;
  margin: 0 10px;
  border: 2px solid var(--primary);
  background-color: transparent;
  font-weight: 400;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  p {
    margin: 0;
  }
}




.gradient_button {
  height: 40px;
  width: 150px;
  font-weight: 500;
  font-size: 15px;
  margin: 0 10px;
  background: linear-gradient(to right, var(--secondary), var(--primary));
  border: none;
  font-weight: 400;
  border-radius: 500px;
  color: var(--slate-palette-600);;
  cursor: pointer;
  p {
    margin: 0;
  }
}

//Botón gris
.back_button {
  height: 40px;
  width: 150px;
  font-weight: 500;
  font-size: 15px;
  margin: 5px;
  border: 2px solid lightgrey;
  background-color: transparent;
  font-weight: 400;
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  p {
    margin: 0;
  }
  &:hover {
    background: lightgrey;
    transition: 0.5s;
    border-color: transparent;
  }
}

.blue-private-button {
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  background-color: var(--primary);
  gap: 8px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  p{
    color: white;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }
  &:hover {
    background-color: var(--brand-palette-600);
  }
  &:disabled {
   background-color: var(--slate-palette-200);
    p{
      color: var(--slate-palette-400);
    }
  }
}
.blue-private-stroked-button {
  border-radius: 8px;
  border: 1px solid var(--slate-palette-300);
  padding: 10px 20px;
  background-color:transparent;
  gap: 8px;
  color: var(--primary);
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  p{
    color: var(--primary);
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }
  &:hover {
    background-color: var(--brand-palette-500);
    color: white;
    p{
      color: white;
    }
  }
}

@media (max-width: $mobile) {
  .gold-color-button{
    font-size: 14px;
    padding: 8px 16px;
    height: auto;
  }
  .gold-stroked-button {
    font-size: 14px;
    padding: 8px 16px;
    height: auto;
  }
}