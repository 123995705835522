.tableMaterial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  box-shadow: 0px 1px 2px 0px rgba(28, 39, 49, 0.08);
  border-radius: 10px;
  border: 1px solid #E2E8F0;

  th {
    font-size: 14px;
    font-weight: 600;
    background-color: var(--brand-palette-100) !important;
    color: #1E293B;
    border-bottom: 1px solid #E2E8F0;
    line-height: 16px;
  }
  th:first-child {
    border-top-left-radius: 10px !important;
  }
  th:last-child {
    border-top-right-radius: 10px !important;
  }
  .operation {
    color: #9F8A5F;
    font-weight: 500;
  }
  tr:nth-child(odd) td {
    background-color: white;
  }
  tr:nth-child(even) td {
    background-color: #f9fafb;
  }
  td {
    border-bottom: 1px solid #E2E8F0;
    mat-icon{
      color: var(--primary);
      font-size: large;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }
    .op-date,
    .vat-number {
      color: #334155;
    }
    .companyName,
    .type,
    .amount {
      color: #565656;
    }
    .total {
      font-size: 14px;
      font-weight: 300;
      color: #64748B;
    }
    .status-aprobado {
      color: var(--approved);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--approved-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }
  
    .status-pendiente {
      color: #5C6970;
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: #F1F3F4;
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }
  
    .status-completado {
      color: var(--completed);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--completed-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }
  
    .status-firmado {
      color: var(--pending);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--pending-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }
  
    .status-cancelado, .status-denegado {
      color: var(--rejected);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--rejected-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }
  
/*     height: 76px; */
  }
}

.mdc-data-table__row:hover {
  background-color: var(--slate-palette-50);
}

.clickable {
  color: var(--secondary);
  margin: 0;
  cursor: pointer;
}
