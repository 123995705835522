@use '../0-base/responsive' as *;


.header_container, .onboarding-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF1A;
  backdrop-filter: blur(4px);
  z-index: 2;
  height: 104px;
  justify-content: space-between;
  align-items: center;

  .upper-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo_container {
      margin-left: 40px;
    }
    .buttons_container {
      max-width: fit-content;
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 10px;
      margin-right: 40px;
      .gold-color-button{
        width: 149px;
      }
      .header-icons {
        margin-right: 10px;
        display: flex;
        mat-icon {
          font-size: 30px;
          width: 30px;
          height: 30px;
          margin: 0 5px;
          color: var(--secondary);
        }
      }
    }
    .logo_container {
      img {
        max-width: 181.07px;
        cursor: pointer;
      }
    }
  }
}

.diagonal_header {
  margin: 0;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  padding: 30px;
  background-size: cover;
  height: 700px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%);

  .header_title {
    font-family: var(--font);
    font-size: 60px;
    font-weight: bolder;
    padding-left: 50px;
    line-height: 1;
  }

  .header_subtitle {
    font-weight: 100;
    margin: 30px 25px 25px 40px;
    padding-bottom: 50px;
  }

  img {
    width: 100%;
  }

  .header_title,
  .header_subtitle {
    display: flex;
    align-items: flex-end;
    z-index: 1;
    
    color: white;
    width: 700px;
    max-width: 100%;
  }
}

.onboarding-page{
  background-color: var(--brand-palette-700);
}

@media (max-width: $tablet) {
.diagonal_header{
  .header_title{
    padding: 0;
  }
  .header_subtitle{
    margin: 0;
  }
}
}
@media (max-width: $mobile) {
  
}
