@use "src/styles/0-base/_responsive.scss" as *;
.subtitle-container {
    margin-top: 60px;
    margin-bottom: 10px;
    h2 {
      font-family: var(--font);
      color: var(--slate-palette-500);;
      font-size: x-large;
      margin: 0;
      margin-left: 5px;
    }
  }
 .to-do-doc {
    margin-bottom: 20px;
    background-color: white;
    flex-wrap: wrap;
    border-radius: 10px;
    display: flex;
    background-color: var(--light-grey);
    .no-data {
      font-size: medium;
      font-weight: 300;
      margin: 0;
      padding: 20px 0px;
    }
  }

  .document-box {
    width: 250px;
  /*   min-height: 130px; */
    height: fit-content;
    background-color: var(--slate-palette-600);;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px;
    padding: 10px;
    p {
      font-weight: 400;
      font-size: medium;
      display: flex;
      max-width: 100%;
      gap: 5px;
      cursor: default;
      .subtitle{
        font-size: small;
        font-weight: 300;
      }
      mat-icon{
        font-size: 21px;
        width: 25px;
        height: 25px;
        color: var(--light-grey);
      }
     
    }
    .partnerName {
        font-size: small;
        font-weight: 300;
      }
    color: white;
   /*  p {
      padding: 10px;
    } */
   /*  .upload_file {
      width: 100%;
      button {
        width: 100%;
        align-self: center;
        min-height: 42px;
        border-radius: 50px;
        border: 2px solid var(--light-grey);
        background-color: var(--light-grey);
      }
    } */
  
  }