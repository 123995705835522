/* You can add global styles to this file, and also import other style files */

@use './0-base/base_dir' as *;
@use './1-components/base_dir' as *;

/* html, body { height: 100%; } */
body {
    margin: 0;
    width: 100%;
    min-height: 100%;
    background-color: #fcfcfc;
    font-family: var(--font) !important;

}
*{
    font-family: var(--font) !important;
}


mat-icon{
    font-family: 'Material Icons' !important;
}
/* .mat-tab-labels{
    justify-content: center !important;
} */
.mat-mdc-tab-body-content {
  background-color: var(--white);
}
.mat-mdc-table{
    background-color: transparent !important;
}

html{
    overflow-y: auto;
      scrollbar-color: var(--primary) #f9fafd;
      scrollbar-width: thin;
  }


mat-label {
    color: var(--slate-palette-600);
  }

  .mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background, .mdc-checkbox__ripple {
    background-color: var(--brand-palette-300) !important;
    border-color: var(--brand-palette-300) !important;
}

.mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-unselected-icon-color: var(--slate-palette-300);
    --mdc-checkbox-unselected-pressed-icon-color: var(--slate-palette-300);
    --mdc-checkbox-unselected-hover-icon-color: var(--slate-palette-300);
    --mdc-checkbox-unselected-focus-icon-color: var(--slate-palette-300);
}

.mdc-checkbox__ripple { display: none; }



.checkboxLogin{
    .mat-mdc-checkbox label {
        color: grey
    }
    .mat-mdc-checkbox input {
        border-color: violet;
      }
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 15px;
  }


.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: white !important;
    padding: 0 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
    padding: 0 !important;
}


mat-checkbox {
    transform: scale(0.8);
  }

.pointer {
    cursor:pointer;
}

.w100{
    width: 100%;
  }

.mat-mdc-form-field-error {
    display: block;
    color: var(--error) !important;
}
