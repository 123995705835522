
// Import material theming functions
@use 'SASS:map';
@use '@angular/material' as mat;

// Theme
$md-brand: (
    50 : #E7F2F3,
    100 : #D0E4E7,
    200 : #A1C9CF,
    300 : #71AEB7,
    400 : #42939F,
    500 : #137887,
    600 : #0F606C,
    700 : #0B4851,
    800 : #083036,
    900 : #062428,
    950 : #020C0D,
    A100 : #ffffff,
    A200 : #f3fff9,
    A400 : #c0ffe1,
    A700 : #a7ffd4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$md-gold: (
    50 : #F9F7F1,
    100 : #F4EFE4,
    200 : #E9DEC9,
    300 : #DDCEAD,
    400 : #D2BD92,
    500 : #C7AD77,
    600 : #9F8A5F,
    700 : #776847,
    800 : #776847,
    900 : #282318,
    950 : #14110C,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #e1f4ff,
    A700 : #c8ebff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-slate: (
    50 : #F8FAFC,
    100 : #F1F5F9,
    200 : #E2E8F0,
    300 : #CBD5E1,
    400 : #94A3B8,
    500 : #64748B,
    600 : #475569,
    700 : #334155,
    800 : #1E293B,
    900 : #0F172A,
    950 : #020617,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #e1f4ff,
    A700 : #c8ebff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//Global

$my-primary: mat.define-palette($md-brand, 500);
$my-secondary: mat.define-palette($md-gold, 500);
$my-warn: mat.define-palette($md-slate);
$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-secondary,
   warn: $my-warn,
    
 )
));
@include mat.all-component-themes($my-theme);

//Colors
$color-config: mat.get-color-config($my-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette:  map.get($color-config, 'accent');
$warn-palette:  map.get($color-config, 'warn');
$primary: mat.get-color-from-palette($primary-palette, 500);
$secondary: mat.get-color-from-palette($accent-palette, 500);
$light-secondary: mat.get-color-from-palette($accent-palette, 300);
$light-primary: mat.get-color-from-palette($primary-palette, 300);
$warn: mat.get-color-from-palette($warn-palette);
$brand-palette-50: mat.get-color-from-palette($primary-palette, 50);
$brand-palette-100: mat.get-color-from-palette($primary-palette, 100);
$brand-palette-200: mat.get-color-from-palette($primary-palette, 200);
$brand-palette-300: mat.get-color-from-palette($primary-palette, 300);
$brand-palette-400: mat.get-color-from-palette($primary-palette, 400);
$brand-palette-500: mat.get-color-from-palette($primary-palette, 500);
$brand-palette-600: mat.get-color-from-palette($primary-palette, 600);
$brand-palette-700: mat.get-color-from-palette($primary-palette, 700);
$brand-palette-800: mat.get-color-from-palette($primary-palette, 800);
$brand-palette-900: mat.get-color-from-palette($primary-palette, 900);
$brand-palette-950: mat.get-color-from-palette($primary-palette, 950);
$gold-palette-50: mat.get-color-from-palette($accent-palette, 50);
$gold-palette-100: mat.get-color-from-palette($accent-palette, 100);
$gold-palette-200: mat.get-color-from-palette($accent-palette, 200);
$gold-palette-300: mat.get-color-from-palette($accent-palette, 300);
$gold-palette-400: mat.get-color-from-palette($accent-palette, 400);
$gold-palette-500: mat.get-color-from-palette($accent-palette, 500);
$gold-palette-600: mat.get-color-from-palette($accent-palette, 600);
$gold-palette-700: mat.get-color-from-palette($accent-palette, 700);
$gold-palette-800: mat.get-color-from-palette($accent-palette, 800);
$gold-palette-900: mat.get-color-from-palette($accent-palette, 900);
$gold-palette-950: mat.get-color-from-palette($accent-palette, 950);
$slate-palette-50: mat.get-color-from-palette($warn-palette, 50);
$slate-palette-100: mat.get-color-from-palette($warn-palette, 100);
$slate-palette-200: mat.get-color-from-palette($warn-palette, 200);
$slate-palette-300: mat.get-color-from-palette($warn-palette, 300);
$slate-palette-400: mat.get-color-from-palette($warn-palette, 400);
$slate-palette-500: mat.get-color-from-palette($warn-palette, 500);
$slate-palette-600: mat.get-color-from-palette($warn-palette, 600);
$slate-palette-700: mat.get-color-from-palette($warn-palette, 700);
$slate-palette-800: mat.get-color-from-palette($warn-palette, 800);
$slate-palette-900: mat.get-color-from-palette($warn-palette, 900);
$slate-palette-950: mat.get-color-from-palette($warn-palette, 950);

//PALETTE BASICS
$lightTurqoise: #a3f3d0;
$backgroundBlue: #f0f9ff;

$body: #334054;
$error: #f64c4c;
$error-light: #f88686;
$disabled: #f3f3f1;
$blue-primary: #137888;
$blue-secondary: #8cbdc9;



:root {
    --primary: #{$primary};
    --secondary: #{$secondary};
    --light-secondary: #{$light-secondary};
    --light-primary: #{$light-primary};
    --warn: #{$warn};
    --body: #{$body};
    --error: #{$error};
    --error-light: #{$error-light};
    --disabled: #{$disabled};
    --blue-primary: #{$blue-primary};
    --blue-secondary: #{$blue-secondary};
    --lightTurqoise: #{$lightTurqoise};
    --backgroundBlue: #{$backgroundBlue};
    --brand-palette-50: #{$brand-palette-50};
    --brand-palette-100: #{$brand-palette-100};
    --brand-palette-200: #{$brand-palette-200};
    --brand-palette-300: #{$brand-palette-300};
    --brand-palette-400: #{$brand-palette-400};
    --brand-palette-500: #{$brand-palette-500};
    --brand-palette-600: #{$brand-palette-600};
    --brand-palette-700: #{$brand-palette-700};
    --brand-palette-800: #{$brand-palette-800};
    --brand-palette-900: #{$brand-palette-900};
    --brand-palette-950: #{$brand-palette-950};
    --gold-palette-50: #{$gold-palette-50};
    --gold-palette-100: #{$gold-palette-100};
    --gold-palette-200: #{$gold-palette-200};
    --gold-palette-300: #{$gold-palette-300};
    --gold-palette-400: #{$gold-palette-400};
    --gold-palette-500: #{$gold-palette-500};
    --gold-palette-600: #{$gold-palette-600};
    --gold-palette-700: #{$gold-palette-700};
    --gold-palette-800: #{$gold-palette-800};
    --gold-palette-900: #{$gold-palette-900};
    --gold-palette-950: #{$gold-palette-950};
    --slate-palette-50: #{$slate-palette-50};
    --slate-palette-100: #{$slate-palette-100};
    --slate-palette-200: #{$slate-palette-200};
    --slate-palette-300: #{$slate-palette-300};
    --slate-palette-400: #{$slate-palette-400};
    --slate-palette-500: #{$slate-palette-500};
    --slate-palette-600: #{$slate-palette-600};
    --slate-palette-700: #{$slate-palette-700};
    --slate-palette-800: #{$slate-palette-800};
    --slate-palette-900: #{$slate-palette-900};
    --slate-palette-950: #{$slate-palette-950};
}