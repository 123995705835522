@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');

$font: 'Nunito Sans', sans-serif;
$secondFont: 'Bitter', serif;

@mixin base-typo($size, $weight, $spacing) {
  font-weight: $weight;
  font-size: $size;
  letter-spacing: $spacing;
}

:root {
  --font: #{$font};
  --secondFont: #{$secondFont};
}